<template>
  <div class="layout-subcontainer">
    <!-- 프레임워크 명령어 -->
    <v-card class="flex-sm-grow-1 pa-3" elevation="2" max-width="" outlined>
      <v-card-title primary-title class="font-weight-bold primary--text">
        프레임워크 설치(vue -cli)
      </v-card-title>
      <v-card-text>
        <span class="font-weight-bold"
          >[node.js(NPM)을 설치해야 하는 경우]</span
        >
        <br /><a
          href="https://yongku.tistory.com/entry/%EC%9C%88%EB%8F%84%EC%9A%B010Windows-10%EC%97%90%EC%84%9C-NPM%EC%9D%84-%EC%82%AC%EC%9A%A9%ED%95%98%EA%B8%B0-%EC%9C%84%ED%95%9C-Nodejs-%EC%84%A4%EC%B9%98%ED%95%98%EB%8A%94-%EB%B0%A9%EB%B2%95"
          target="_blank"
          class="mt-3"
          >※ 원도우10에 node.js(npm) 설치 하는 방법 바로가기</a
        >
        <br /><br /><span class="font-weight-bold">[설치]</span> <br /><span
          class="blue lighten-5 pa-1"
          >npm install -g @vue/cli</span
        >
        또는
        <span class="blue lighten-5 pa-1">yarn global add @vue/cli</span>
        명령어로 설치 <br /><br /><span class="font-weight-bold">[설치후]</span>
        <br /><span class="blue lighten-5 pa-1">vue --version</span> 명령어로
        설치 여부 확인 <br /><span class="pink--text"
          >※ 명령어가 정상적으로 동작하는지 확인 (ex: @vue/cli 4.5.15)</span
        >
      </v-card-text>

      <v-card-title primary-title class="font-weight-bold primary--text mt-10">
        프로그램 소스 다운로드
      </v-card-title>
      <v-card-text>
        <span class="font-weight-bold">[설치 경로로 이동 후]</span>
        <br /><span class="blue lighten-5 pa-1"
          >http://192.168.103.250:9001/root/palette2-hkcloud-front-end.git</span
        >
        명령어 실행 <br /><span class="pink--text"
          >※ palette2-hkcloud-front-end 폴더가 정상적으로 생성 되어 있는지
          확인</span
        >
      </v-card-text>

      <v-card-title primary-title class="font-weight-bold primary--text mt-10">
        프레임워크 명령어
      </v-card-title>
      <v-card-text>
        <span class="font-weight-bold">[프로그램 경로로 이동]</span>
        <br />다운로드된
        <span class="blue lighten-5 pa-1">palette2-hkcloud-front-end</span>
        폴더로 이동

        <br /><br /><span class="font-weight-bold">[개발서버 실행]</span>
        <br /><span class="blue lighten-5 pa-1">npm run serve</span> 명령어 실행
        <br /><span class="pink--text"
          >※ 명령어 실행시 메모리 빌드 후 아래와 같이 url 정보가 보이게 되면
          성공</span
        >
        <code>
          <br />App running at: <br />- Local: http://localhost:3004/ <br />-
          Network: http://192.168.103.18:3004/
        </code>
        <v-simple-table dark class="mt-5">
          <thead>
            <tr>
              <th class="text-center">이름</th>
              <th class="text-center">명령어</th>
              <th class="text-center">참고사항</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>개발 서버 시작</td>
              <td>npm run serve</td>
              <td>개발서버 시작(dist폴더에 프로그램 소스는 생성되지 않음)</td>
            </tr>
            <tr>
              <td>프로그램 빌드 시작</td>
              <td>npm run build</td>
              <td>dist폴더에 프로그램 소스를 생성</td>
            </tr>
          </tbody>
        </v-simple-table>

        <v-row class="pt-5">
          <v-col>
            <template v-for="(item, index) in discriptionItem">
              <help-discription
                :key="`subitem-${index}`"
                :item="item"
                class="blue lighten-5"
              ></help-discription>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import HelpDiscription from "./Discription";
export default {
  name: "HelpLayout",
  components: {
    HelpDiscription,
  },
  data: () => ({
    discriptionItem: [
      {
        title: "개발서버 포트 변경",
        text: "package.json파일의 'serve'명령어에 --open --port 옵션으로 port 번호 변경 가능<br />ex) 'serve': 'vue-cli-service serve --open --port 8443'",
      },
    ],
    asdasdassad: "",
  }),
};
</script>

<style scoped>
.v-card__subtitle,
.v-card__text {
  font-size: 1em;
  color: #000 !important;
  line-height: 2;
  padding-left: 40px;
}
</style>
